@import '~antd/dist/antd.css';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700;900&family=Barlow+Semi+Condensed:wght@700&display=swap');

body {
  font-family: 'Roboto', sans-serif;
  line-height: inherit;
  overflow: hidden;
}

.ant-popover-content .ant-popover-arrow {
  display: none;
}

.wrapper {
  width: 468px;
  min-width: 360px;
  position: absolute;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.full-height {
  height: 100vh;
  height: -webkit-fill-available;
}

.rotate {
  position: absolute;
  left: 0;
  top: 0;
  background-color: #000;
  width: 100vw;
  z-index: 5;
  visibility: hidden;
}

.rotate::after {
  content: '';
  position: absolute;
  width: 316px;
  height: 270px;
  left: calc(50% - 127px);
  top: calc(50% - 198px);
  background: url(assets/images/rotateScreen@2x.png) 100% / contain no-repeat;
  transform-origin: 127px 198px;
  transform: rotate(90deg);
}

@media only screen and (max-width: 720px) {
  .wrapper {
    width: 360px;
  }
}

@media only screen and (max-height: 540px) {
  .rotate {
    visibility: visible;
  }
}

@media only screen and (max-height: 375px) {
  .rotate::after {
    transform: rotate(90deg) scale(0.75);
  }
}
