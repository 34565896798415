@font-face {
  font-family: "PoetsenOne";
  src: local("PoetsenOne"),
   url("./fonts/PoetsenOne/PoetsenOne-Regular.ttf") format("truetype");
  font-weight: 400;
}

body {
  margin: 0;
  font-family: 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}